import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
//
import User from 'src/models/user';
import Integration from 'src/models/integration';
import { Product } from '../../../models/product';
import IntegrationOption from '../../../models/integrationOption';
import Company from '../../../models/company';
import { ActionMapType, CreateDataType, DataStateType } from '../types';
import { DataContext } from './data-context';
import { Metric } from '../../../models/metric';
import { IntegrationQuestionType } from '../../../models/integration-question-type';
import { IntegrationPage } from '../../../models/integration-page';
import { IntegrationQuestion } from '../../../models/integration-question';
import IntegrationRoute from '../../../models/integrationRoute';

// ----------------------------------------------------------------------
enum Types {
  // INITIAL
  INITIAL = 'INITIAL',
  // ADD
  ADD_INTEGRATION_TYPES = 'ADD_INTEGRATION_TYPES',
  ADD_INTEGRATION_OPTIONS = 'ADD_INTEGRATION_OPTIONS',
  // UPDATE
  UPDATE_COMPANIES = 'UPDATE_COMPANIES',
  UPDATE_USERS = 'UPDATE_USERS',
  UPDATE_PRODUCTS = 'UPDATE_PRODUCTS',
  UPDATE_METRICS = 'UPDATE_METRICS',
  UPDATE_CATEGORIES = 'UPDATE_CATEGORIES',
  UPDATE_INTEGRATIONS_FILTER = 'UPDATE_INTEGRATIONS_FILTER',
  UPDATE_METRIC_FILTER = 'UPDATE_METRIC_FILTER',
  UPDATE_PRODUCTS_FILTER = 'UPDATE_PRODUCTS_FILTER',
  UPDATE_FEEDBACK_FILTER = 'UPDATE_FEEDBACK_FILTER',
  UPDATE_SELECTED_COMPANY = 'UPDATE_SELECTED_COMPANY',
  UPDATE_INTEGRATION_OPTION = 'UPDATE_INTEGRATION_OPTION',
  UPDATE_INTEGRATION = 'UPDATE_INTEGRATION',
  UPDATE_INTEGRATION_PAGES = 'UPDATE_INTEGRATION_PAGES',
  UPDATE_SELECTED_PAGE = 'UPDATE_SELECTED_PAGE',
  UPDATE_INTEGRATION_QUESTIONS = 'UPDATE_INTEGRATION_QUESTIONS',
  UPDATE_SELECTED_QUESTION = 'UPDATE_SELECTED_QUESTION',
  UPDATE_INTEGRATION_ROUTES = 'UPDATE_INTEGRATION_ROUTES',
  UPDATE_LANGUAGE = 'UPDATE_LANGUAGE',
}

type Payload = {
  // INITIAL
  [Types.INITIAL]: DataStateType;
  // ADD
  [Types.ADD_INTEGRATION_TYPES]: {
    integrationTypes: IntegrationQuestionType[];
  };
  [Types.ADD_INTEGRATION_OPTIONS]: {
    integrationOptions: IntegrationOption[];
  };
  // UPDATE
  [Types.UPDATE_COMPANIES]: {
    companies: Company[];
  };
  [Types.UPDATE_USERS]: {
    users: User[];
  };
  [Types.UPDATE_PRODUCTS]: {
    products: Product[];
  };
  [Types.UPDATE_METRICS]: {
    metrics: Metric[];
  };
  [Types.UPDATE_CATEGORIES]: {
    categories: string[];
  };
  [Types.UPDATE_INTEGRATIONS_FILTER]: {
    integrationsFilter: Integration[];
  };
  [Types.UPDATE_METRIC_FILTER]: {
    metricFilter: { group: string; value: string }[];
  };
  [Types.UPDATE_PRODUCTS_FILTER]: {
    productsFilter: Product[];
  };
  [Types.UPDATE_FEEDBACK_FILTER]: {
    feedbackFilter: string[];
  };
  [Types.UPDATE_SELECTED_COMPANY]: {
    selectedCompany: string;
  };
  [Types.UPDATE_INTEGRATION_OPTION]: {
    integrationOptions: IntegrationOption[];
  };
  [Types.UPDATE_INTEGRATION]: {
    integration: Integration | null;
  };
  [Types.UPDATE_INTEGRATION_PAGES]: {
    pages: IntegrationPage[];
  };
  [Types.UPDATE_SELECTED_PAGE]: {
    page: IntegrationPage | null;
  };
  [Types.UPDATE_INTEGRATION_QUESTIONS]: {
    questions: IntegrationQuestion[];
  };
  [Types.UPDATE_SELECTED_QUESTION]: {
    question: IntegrationQuestion | null;
  };
  [Types.UPDATE_INTEGRATION_ROUTES]: {
    routes: IntegrationRoute[];
  };
  [Types.UPDATE_LANGUAGE]: {
    language: string;
  };
};

type Action = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: DataStateType = {
  companies: [],
  users: [],
  products: [],
  metrics: [],
  categories: [],
  integrationsFilter: [],
  metricFilter: [],
  productsFilter: [],
  feedbackFilter: [],
  selectedCompany: '',
  integrationTypes: [],
  integrationOptions: [],
  integration: null,
  integrationPages: [],
  selectedPage: null,
  integrationQuestions: [],
  selectedQuestion: null,
  integrationRoutes: [],
  language: '',
};

const reducer = (state: DataStateType, action: Action) => {
  // INITIAL
  if (action.type === Types.INITIAL) {
    return initialState;
  }

  // ADD
  if (action.type === Types.ADD_INTEGRATION_TYPES) {
    return {
      ...state,
      integrationTypes: action.payload.integrationTypes,
    };
  }

  if (action.type === Types.ADD_INTEGRATION_OPTIONS) {
    return {
      ...state,
      integrationOptions: action.payload.integrationOptions,
    };
  }

  // UPDATE
  if (action.type === Types.UPDATE_COMPANIES) {
    return {
      ...state,
      companies: action.payload.companies,
    };
  }

  if (action.type === Types.UPDATE_USERS) {
    return {
      ...state,
      users: action.payload.users,
    };
  }

  if (action.type === Types.UPDATE_PRODUCTS) {
    return {
      ...state,
      products: action.payload.products,
    };
  }

  if (action.type === Types.UPDATE_METRICS) {
    return {
      ...state,
      metrics: action.payload.metrics,
    };
  }

  if (action.type === Types.UPDATE_SELECTED_COMPANY) {
    return {
      ...state,
      selectedCompany: action.payload.selectedCompany,
    };
  }

  if (action.type === Types.UPDATE_INTEGRATION_OPTION) {
    return {
      ...state,
      integrationOptions: action.payload.integrationOptions,
    };
  }

  if (action.type === Types.UPDATE_INTEGRATION) {
    return {
      ...state,
      integration: action.payload.integration,
    };
  }

  if (action.type === Types.UPDATE_INTEGRATION_PAGES) {
    return {
      ...state,
      integrationPages: action.payload.pages,
    };
  }

  if (action.type === Types.UPDATE_SELECTED_PAGE) {
    return {
      ...state,
      selectedPage: action.payload.page,
      selectedQuestion: null,
    };
  }

  if (action.type === Types.UPDATE_INTEGRATION_QUESTIONS) {
    return {
      ...state,
      integrationQuestions: action.payload.questions,
    };
  }

  if (action.type === Types.UPDATE_SELECTED_QUESTION) {
    return {
      ...state,
      selectedQuestion: action.payload.question,
    };
  }

  if (action.type === Types.UPDATE_INTEGRATION_ROUTES) {
    return {
      ...state,
      integrationRoutes: action.payload.routes,
    };
  }

  if (action.type === Types.UPDATE_LANGUAGE) {
    return {
      ...state,
      language: action.payload.language,
    };
  }

  return state;
};
// ----------------------------------------------------------------------

type DataProviderProps = {
  children: React.ReactNode;
};

export function DataProvider({ children }: DataProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [type, setType] = useState<CreateDataType | null>(null);

  const [company, setCompany] = useState<Company | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [product, setProduct] = useState<Product | null>(null);
  const [metric, setMetric] = useState<Metric | null>(null);

  const initialize = useCallback(() => {
    dispatch({
      type: Types.INITIAL,
      payload: initialState,
    });
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // Add
  const addIntegrationTypes = useCallback((integrationTypes: IntegrationQuestionType[]) => {
    dispatch({
      type: Types.ADD_INTEGRATION_TYPES,
      payload: {
        integrationTypes,
      },
    });
  }, []);

  const addIntegrationOptions = useCallback((integrationOptions: IntegrationOption[]) => {
    dispatch({
      type: Types.ADD_INTEGRATION_OPTIONS,
      payload: {
        integrationOptions,
      },
    });
  }, []);

  // Update
  const updateCompaniesState = useCallback(
    (companies: Company[], init: boolean = false) => {
      const data = init ? companies : [...companies, ...state.companies];
      dispatch({
        type: Types.UPDATE_COMPANIES,
        payload: {
          companies: data,
        },
      });
    },
    [state]
  );

  const updateUsersState = useCallback(
    (users: User[], init: boolean = false) => {
      const data = init ? users : [...users, ...state.users];

      dispatch({
        type: Types.UPDATE_USERS,
        payload: {
          users: data,
        },
      });
    },
    [state]
  );

  const updateProductsState = useCallback(
    (products: Product[], init: boolean = false) => {
      const data = init ? products : [...products, ...state.products];

      dispatch({
        type: Types.UPDATE_PRODUCTS,
        payload: {
          products: data,
        },
      });
    },
    [state]
  );

  const updateMetricsState = useCallback(
    (metrics: Metric[], init: boolean = false) => {
      const data = init ? metrics : [...metrics, ...state.metrics];

      dispatch({
        type: Types.UPDATE_METRICS,
        payload: {
          metrics: data,
        },
      });
    },
    [state]
  );

  const updateCategories = useCallback((categories: string[]) => {
    dispatch({
      type: Types.UPDATE_CATEGORIES,
      payload: {
        categories,
      },
    });
  }, []);

  const updateIntegrationsFilter = useCallback((integrationsFilter: Integration[]) => {
    dispatch({
      type: Types.UPDATE_INTEGRATIONS_FILTER,
      payload: {
        integrationsFilter,
      },
    });
  }, []);

  const updateMetricFilter = useCallback((metricFilter: { group: string; value: string }[]) => {
    dispatch({
      type: Types.UPDATE_METRIC_FILTER,
      payload: {
        metricFilter,
      },
    });
  }, []);

  const updateProductsFilter = useCallback((productsFilter: Product[]) => {
    dispatch({
      type: Types.UPDATE_PRODUCTS_FILTER,
      payload: {
        productsFilter,
      },
    });
  }, []);

  const updateFeedbackFilter = useCallback((feedbackFilter: string[]) => {
    dispatch({
      type: Types.UPDATE_FEEDBACK_FILTER,
      payload: {
        feedbackFilter,
      },
    });
  }, []);

  const updateSelectedCompanyState = useCallback((selectedCompany: string) => {
    localStorage.setItem('selectedCompany', selectedCompany);
    dispatch({
      type: Types.UPDATE_SELECTED_COMPANY,
      payload: {
        selectedCompany,
      },
    });
  }, []);

  const updateIntegrationOptionState = useCallback(
    (integrationOption: IntegrationOption) => {
      const integrationOptions = state.integrationOptions.map((option) => {
        if (option.id === integrationOption.id) {
          return integrationOption;
        }
        return option;
      });

      dispatch({
        type: Types.UPDATE_INTEGRATION_OPTION,
        payload: {
          integrationOptions,
        },
      });
    },
    [state]
  );

  const updateIntegrationState = useCallback((integration: Integration | null) => {
    if (integration && integration.pages && integration.pages.length > 0) {
      integration.pages = integration.pages
        .filter((p) => p.status === 'ACTIVE')
        .sort((a, b) => a.position - b.position);
    }

    dispatch({
      type: Types.UPDATE_INTEGRATION,
      payload: {
        integration,
      },
    });
  }, []);

  const updateLanguage = useCallback((language: string) => {
    localStorage.setItem('lang', language);
    dispatch({
      type: Types.UPDATE_LANGUAGE,
      payload: {
        language,
      },
    });
  }, []);

  const updateIntegrationPages = useCallback((pages: IntegrationPage[]) => {
    dispatch({
      type: Types.UPDATE_INTEGRATION_PAGES,
      payload: {
        pages,
      },
    });
  }, []);

  const updateSelectedPage = useCallback((page: IntegrationPage | null) => {
    dispatch({
      type: Types.UPDATE_SELECTED_PAGE,
      payload: {
        page,
      },
    });
  }, []);

  const updateIntegrationQuestions = useCallback((questions: IntegrationQuestion[]) => {
    dispatch({
      type: Types.UPDATE_INTEGRATION_QUESTIONS,
      payload: {
        questions,
      },
    });
  }, []);

  const updateSelectedQuestion = useCallback((question: IntegrationQuestion | null) => {
    dispatch({
      type: Types.UPDATE_SELECTED_QUESTION,
      payload: {
        question,
      },
    });
  }, []);

  const updateIntegrationRoutes = useCallback((routes: IntegrationRoute[]) => {
    dispatch({
      type: Types.UPDATE_INTEGRATION_ROUTES,
      payload: {
        routes,
      },
    });
  }, []);

  // Ref
  const generateRef = useCallback(
    (title: string, id: string): string => {
      let ref =
        !title && title !== ''
          ? Math.random().toString(36).substring(2, 15)
          : title.toLowerCase().replace(/[^a-z0-9]/g, '');

      const checkIfRefExists = (r: string) =>
        state.integrationQuestions?.filter((q) => q.ref === r && q.id !== id).length > 0;

      // Check if some question have the same ref, if yes generate a new one adding _1, _2, _3, etc
      while (checkIfRefExists(ref)) {
        // If the last character is a number, increment it
        if (ref.charAt(ref.length - 1).match(/[0-9]/)) {
          const lastChar = ref.charAt(ref.length - 1);
          ref = ref.slice(0, -1);
          ref = `${ref}${parseInt(lastChar, 10) + 1}`;
        } else {
          ref = `${ref}1`;
        }
      }

      return ref;
    },
    [state.integrationQuestions]
  );

  // Drawer
  const onToggleDrawer = useCallback((typeToOpen: CreateDataType, editableObj: any = null) => {
    setCompany(null);
    setUser(null);
    setProduct(null);
    setMetric(null);
    setType(typeToOpen);

    if (editableObj) {
      switch (typeToOpen) {
        case CreateDataType.COMPANY:
          setCompany(editableObj as Company);
          break;
        case CreateDataType.USER:
          setUser(editableObj as User);
          break;
        case CreateDataType.PRODUCT:
          setProduct(editableObj as Product);
          break;
        case CreateDataType.METRIC:
          setMetric(editableObj as Metric);
          break;
        default:
          break;
      }
    }

    setOpenDrawer((prev) => !prev);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
    setType(null);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      ...state,
      // Add
      addIntegrationTypes,
      addIntegrationOptions,
      // Update
      updateCompaniesState,
      updateUsersState,
      updateProductsState,
      updateMetricsState,
      updateCategories,
      updateIntegrationsFilter,
      updateMetricFilter,
      updateProductsFilter,
      updateFeedbackFilter,
      updateSelectedCompanyState,
      updateIntegrationOptionState,
      updateIntegrationState,
      updateLanguage,
      updateSelectedPage,
      updateIntegrationPages,
      updateSelectedQuestion,
      updateIntegrationQuestions,
      updateIntegrationRoutes,
      // Ref
      generateRef,
      // Drawer
      open: openDrawer,
      onToggle: onToggleDrawer,
      onClose: onCloseDrawer,
      type,
      company,
      user,
      product,
      metric,
    }),
    [
      state,
      // Add
      addIntegrationTypes,
      addIntegrationOptions,
      // Update
      updateCompaniesState,
      updateUsersState,
      updateProductsState,
      updateMetricsState,
      updateCategories,
      updateIntegrationsFilter,
      updateMetricFilter,
      updateProductsFilter,
      updateFeedbackFilter,
      updateSelectedCompanyState,
      updateIntegrationOptionState,
      updateIntegrationState,
      updateLanguage,
      updateSelectedPage,
      updateIntegrationPages,
      updateSelectedQuestion,
      updateIntegrationQuestions,
      updateIntegrationRoutes,
      // Ref
      generateRef,
      // Drawer
      openDrawer,
      onCloseDrawer,
      onToggleDrawer,
      type,
      company,
      user,
      product,
      metric,
    ]
  );

  return <DataContext.Provider value={memoizedValue}>{children}</DataContext.Provider>;
}
