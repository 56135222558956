import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import React, { ReactElement, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormControl, FormGroup, InputLabel, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { AutoAwesome, Lock } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getAssets, getValues, IntegrationQuestion } from '../../../models/integration-question';
import {
  renderInput,
  renderMultipleChoise,
  renderSwitchButton,
  renderTypeOrIcon,
} from '../../dynamicRequestForm/utils';
import { useDataContext } from '../../data';
import { CustomFile, UploadAvatar } from '../../upload';
import { fData } from '../../../utils/format-number';
import { postCompanyFavIcon, postCompanyLogo } from '../../../services/companies';
import { postIntegrationImageUrl } from '../../../services/integrations';
import MultipleChoseImageList from '../../dynamicRequestForm/MultipleChoseImageList';
import QuestionTypePopover from '../questionTypePopover';
import { usePopover } from '../../custom-popover';
import { SvgPath } from '../../animate/svg-path';
import './switch.css';
import InputSelectList from '../../dynamicRequestForm/utils/InputSelectList';
import { InfoButton } from '../../infoButton/infoButton';
import { RenderLabel } from '../../dynamicRequestForm/utils/parseLabelLang';

export default function QuestionFields() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedCompany,
    language,
    integrationQuestions,
    selectedQuestion,
    integrationRoutes,
    updateSelectedQuestion,
    generateRef,
  } = useDataContext();
  const questionType = selectedQuestion?.type;

  const popover = usePopover();

  const renderLabel = (name: string | any) => name[language] || name.en || name;

  const handleDropImage = useCallback(
    async (key: string, acceptedFiles: File[]): Promise<string> => {
      const newFile = acceptedFiles[0];
      const processRes = (
        res: string,
        successMessage: string,
        errorMessage: string
      ): Promise<string> => {
        if (res !== '') {
          enqueueSnackbar(successMessage, { variant: 'success' });
          return Promise.resolve(res);
        }
        enqueueSnackbar(errorMessage, { variant: 'error' });
        return Promise.resolve('');
      };
      if (newFile) {
        let res = '';
        switch (key) {
          case 'logo':
            res = await postCompanyLogo(selectedCompany, newFile);
            return processRes(res, t('success.logoUpdated'), t('error.logoUpdated'));
          case 'favIcon':
            res = await postCompanyFavIcon(selectedCompany, newFile);
            return processRes(res, t('success.faviconUpdated'), t('error.faviconUpdated'));
          default:
            res = await postIntegrationImageUrl(newFile);
            return processRes(res, t('success.imageUploaded'), t('error.imageUploaded'));
        }
      }
      return Promise.resolve('');
    },
    [enqueueSnackbar, selectedCompany, t]
  );

  const renderAddIcon = renderSwitchButton(
    'assets.addIcon',
    renderLabel({
      en: 'Icon',
      es: 'Icono',
      da: 'Ikon',
      fi: 'Kuvake',
      no: 'Ikon',
      sv: 'Ikon',
    }),
    selectedQuestion?.assets?.addIcon || false,
    (id: string, value: boolean) => {
      updateSelectedQuestion({
        ...selectedQuestion,
        assets: { ...selectedQuestion?.assets, addIcon: value },
      } as IntegrationQuestion);
      const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
      if (x) x.assets = { ...x.assets, addIcon: value };
    }
  );

  const renderRandomize = renderSwitchButton(
    'assets.randomPosition',
    renderLabel({
      en: 'Random position',
      es: 'Posición aleatoria',
      da: 'Tilfældig position',
      fi: 'Satunnainen sijainti',
      no: 'Tilfeldig posisjon',
      sv: 'Slumpmässig position',
    }),
    selectedQuestion?.assets?.randomPosition || false,
    (id: string, value: boolean) => {
      updateSelectedQuestion({
        ...selectedQuestion,
        assets: { ...selectedQuestion?.assets, randomPosition: value },
      } as IntegrationQuestion);
      const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
      if (x) x.assets = { ...x.assets, randomPosition: value };
    }
  );

  const renderAddTitle = renderSwitchButton(
    'assets.addTitle',
    renderLabel({
      en: 'Add title to the options',
      es: 'Añadir título a las opciones',
      da: 'Tilføj titel til valgmulighederne',
      fi: 'Lisää otsikko valintoihin',
      no: 'Legg til tittel på valgene',
      sv: 'Lägg till titel på alternativen',
    }),
    selectedQuestion?.assets?.addTitle || false,
    (id: string, value: boolean) => {
      updateSelectedQuestion({
        ...selectedQuestion,
        assets: { ...selectedQuestion?.assets, addTitle: value },
      } as IntegrationQuestion);
      const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
      if (x) x.assets = { ...x.assets, addTitle: value };
    }
  );

  const renderMultiOption = renderSwitchButton(
    'assets.multiOption',
    renderLabel({
      en: 'Multiple option',
      es: 'Opción múltiple',
      da: 'Flere valgmuligheder',
      fi: 'Useita vaihtoehtoja',
      no: 'Flere valgmuligheter',
      sv: 'Flera valmöjligheter',
    }),
    selectedQuestion?.assets?.multiOption || false,
    (id: string, value: boolean) => {
      updateSelectedQuestion({
        ...selectedQuestion,
        assets: { ...selectedQuestion?.assets, multiOption: value },
      } as IntegrationQuestion);
      const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
      if (x) x.assets = { ...x.assets, multiOption: value };
    }
  );

  const renderContactName = renderInput(
    selectedQuestion?.assets?.contactName || '',
    (id, value) => {
      updateSelectedQuestion({
        ...selectedQuestion,
        assets: { ...selectedQuestion?.assets, contactName: value },
      } as IntegrationQuestion);
      const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
      if (x) x.assets = { ...x.assets, contactName: value };
    },
    'assets.contactName',
    renderLabel({
      en: 'Placeholder for name',
      es: 'Sugerencia para nombre',
      da: 'Pladsholder for navn',
      fi: 'Paikkamerkki nimelle',
      no: 'Plassholder for navn',
      sv: 'Platshållare för namn',
    }),
    [],
    'text',
    false
  );

  const renderContactEmail = renderInput(
    selectedQuestion?.assets?.contactEmail || '',
    (id, value) => {
      updateSelectedQuestion({
        ...selectedQuestion,
        assets: { ...selectedQuestion?.assets, contactEmail: value },
      } as IntegrationQuestion);
      const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
      if (x) x.assets = { ...x.assets, contactEmail: value };
    },
    'assets.contactEmail',
    renderLabel({
      en: 'Placeholder for email',
      es: 'Sugerencia para correo electrónico',
      da: 'Pladsholder for email',
      fi: 'Paikkamerkki sähköpostille',
      no: 'Plassholder for e-post',
      sv: 'Platshållare för e-post',
    }),
    [],
    'text',
    false
  );

  const renderMaxCharacters = renderInput(
    selectedQuestion?.assets?.maxCharacters || '',
    (id, value) => {
      updateSelectedQuestion({
        ...selectedQuestion,
        assets: { ...selectedQuestion?.assets, maxCharacters: value },
      } as IntegrationQuestion);
      const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
      if (x) x.assets = { ...x.assets, maxCharacters: value };
    },
    'assets.maxCharacters',
    renderLabel({
      en: 'Max characters',
      es: 'Caracteres máximos',
      da: 'Maksimalt antal tegn',
      fi: 'Maksimimerkit',
      no: 'Maks tegn',
      sv: 'Max tecken',
    }),
    ['0'],
    'number'
  );

  const renderExtraOption = (
    <Stack direction="column" spacing={2} key="extraOption">
      {renderSwitchButton(
        'assets.extraOption',
        renderLabel({
          en: 'Extra option',
          es: 'Opción extra',
          da: 'Ekstra mulighed',
          fi: 'Lisävaihtoehto',
          no: 'Ekstra alternativ',
          sv: 'Extra alternativ',
        }),
        selectedQuestion?.assets?.extraOption || false,
        (id: string, value: boolean) => {
          updateSelectedQuestion({
            ...selectedQuestion,
            assets: { ...selectedQuestion?.assets, extraOption: value },
          } as IntegrationQuestion);
          const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
          if (x) x.assets = { ...x.assets, extraOption: value };
        }
      )}
      {selectedQuestion?.assets?.extraOption &&
        renderInput(
          selectedQuestion?.assets?.extraOptionText || '',
          (id, value) => {
            updateSelectedQuestion({
              ...selectedQuestion,
              assets: { ...selectedQuestion?.assets, extraOptionText: value },
            } as IntegrationQuestion);
            const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
            if (x) x.assets = { ...x.assets, extraOptionText: value };
          },
          'assets.extraOptionText',
          renderLabel({
            en: 'Extra option text',
            es: 'Texto de opción extra',
            da: 'Ekstra mulighed tekst',
            fi: 'Lisävaihtoehdon teksti',
            no: 'Ekstra alternativ tekst',
            sv: 'Extra alternativ text',
          }),
          [],
          'text',
          false
        )}
    </Stack>
  );

  const renderMinMaxValue = (
    <Stack direction="column" spacing={2} key="minMaxValue">
      {renderMultipleChoise(
        selectedQuestion?.assets?.min || '',
        (id, value) => {
          updateSelectedQuestion({
            ...selectedQuestion,
            assets: { ...selectedQuestion?.assets, min: value },
          } as IntegrationQuestion);
          const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
          if (x) x.assets = { ...x.assets, min: value };
        },
        'assets.min',
        renderLabel({
          en: 'Min value',
          es: 'Valor mínimo',
          da: 'Minimumsværdi',
          fi: 'Minimiarvo',
          no: 'Minimumsverdi',
          sv: 'Minsta värde',
        }),
        selectedQuestion?.assets?.min || '0',
        ['0', '1'],
        false
      )}
      {renderMultipleChoise(
        selectedQuestion?.assets?.max || '',
        (id, value) => {
          updateSelectedQuestion({
            ...selectedQuestion,
            assets: { ...selectedQuestion?.assets, max: value },
          } as IntegrationQuestion);
          const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
          if (x) x.assets = { ...x.assets, max: value };
        },
        'assets.max',
        renderLabel({
          en: 'Max value',
          es: 'Valor máximo',
          da: 'Maksimalværdi',
          fi: 'Maksimiarvo',
          no: 'Maksimumsverdi',
          sv: 'Maximalt värde',
        }),
        selectedQuestion?.assets?.max || '10',
        ['2', '3', '4', '5', '6', '7', '8', '9', '10']
      )}
    </Stack>
  );

  const renderMinMaxPlaceholder = (
    <Stack direction="column" spacing={2} key="minMaxPlaceholder">
      {renderInput(
        selectedQuestion?.assets?.minPlaceholder || '',
        (id, value) => {
          updateSelectedQuestion({
            ...selectedQuestion,
            assets: { ...selectedQuestion?.assets, minPlaceholder: value },
          } as IntegrationQuestion);
          const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
          if (x) x.assets = { ...x.assets, minPlaceholder: value };
        },
        'assets.minPlaceholder',
        renderLabel({
          en: 'Min placeholder',
          es: 'Marcador mínimo',
          da: 'Minimumspladsholder',
          fi: 'Minimipaikkamerkki',
          no: 'Minimum plassholder',
          sv: 'Minsta platshållare',
        }),
        [],
        'text',
        false
      )}
      {renderInput(
        selectedQuestion?.assets?.maxPlaceholder || '',
        (id, value) => {
          updateSelectedQuestion({
            ...selectedQuestion,
            assets: { ...selectedQuestion?.assets, maxPlaceholder: value },
          } as IntegrationQuestion);
          const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
          if (x) x.assets = { ...x.assets, maxPlaceholder: value };
        },
        'assets.maxPlaceholder',
        renderLabel({
          en: 'Max placeholder',
          es: 'Marcador máximo',
          da: 'Maksimal pladsholder',
          fi: 'Maksimipaikkamerkki',
          no: 'Maks plassholder',
          sv: 'Maximal platshållare',
        }),
        [],
        'text',
        false
      )}
    </Stack>
  );

  const renderPlaceholder = (
    name: any = {
      en: 'Placeholder',
      es: 'Sugerencia',
      da: 'Pladsholder',
      fi: 'Paikkamerkki',
      no: 'Plassholder',
      sv: 'Platshållare',
    },
    multiple = false
  ) =>
    renderInput(
      selectedQuestion?.assets?.placeholder || '',
      (id, value) => {
        updateSelectedQuestion({
          ...selectedQuestion,
          assets: { ...selectedQuestion?.assets, placeholder: value },
        } as IntegrationQuestion);
        const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
        if (x) x.assets = { ...x.assets, placeholder: value };
      },
      'assets.placeholder',
      renderLabel(name),
      [],
      'text',
      multiple
    );

  const renderSubtitle = renderInput(
    selectedQuestion?.assets?.subtitle || '',
    (id, value) => {
      updateSelectedQuestion({
        ...selectedQuestion,
        assets: { ...selectedQuestion?.assets, subtitle: value },
      } as IntegrationQuestion);
      const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
      if (x) x.assets = { ...x.assets, subtitle: value };
    },
    'assets.subtitle',
    renderLabel({
      en: 'Subtitle',
      es: 'Subtítulo',
      da: 'Undertitel',
      fi: 'Alaotsikko',
      no: 'Undertittel',
      sv: 'Underrubrik',
    }),
    [],
    'text',
    false
  );

  const renderDefaultValue = (type: 'number' | 'text' | 'email' | 'password') =>
    renderInput(
      selectedQuestion?.defaultValue || '',
      (id, value) => {
        updateSelectedQuestion({
          ...selectedQuestion,
          defaultValue: value,
        } as IntegrationQuestion);
        const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
        if (x) x.defaultValue = value;
      },
      'assets.defaultValue',
      renderLabel({
        en: 'Default value',
        es: 'Valor predeterminado',
        da: 'Standardværdi',
        fi: 'Oletusarvo',
        no: 'Standardverdi',
        sv: 'Standardvärde',
      }),
      [],
      type,
      false
    );

  const renderGeneralImage = (
    <UploadAvatar
      file={selectedQuestion?.assets?.general as CustomFile | string | null}
      onDrop={(acceptedFiles) =>
        handleDropImage('assets.general', acceptedFiles).then((res) => {
          updateSelectedQuestion({
            ...selectedQuestion,
            assets: { ...selectedQuestion?.assets, general: res },
          } as IntegrationQuestion);
          const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
          if (x) x.assets = { ...x.assets, general: res };
        })
      }
      placeholder={renderLabel({
        en: 'Image (optional)',
        da: 'Billede (valgfrit)',
        es: 'Imagen (opcional)',
        fi: 'Kuva (valinnainen)',
        no: 'Bilde (valgfritt)',
        sv: 'Bild (valfritt)',
      })}
      maxSize={1048576 * 4}
      helperText={t('text.labels.allowedFormatAndSize') + fData(1048576 * 4)}
      sx={{ height: 200 }}
    />
  );

  const renderMaxMinOptions = (
    <Stack direction="column" spacing={2} key="maxMinOptions">
      {renderInput(
        selectedQuestion?.assets?.maxOptions || '',
        (id, value) => {
          updateSelectedQuestion({
            ...selectedQuestion,
            assets: { ...selectedQuestion?.assets, maxOptions: value },
          } as IntegrationQuestion);
          const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
          if (x) x.assets = { ...x.assets, maxOptions: value };
        },
        'assets.maxOptions',
        renderLabel({
          en: 'Max options to select ( 0 no limit )',
          da: 'Maksimalt antal valg ( 0 ingen begrænsning )',
          es: 'Máximo de opciones a seleccionar ( 0 sin límite )',
          fi: 'Valittavien maksimimäärä ( 0 ei rajoitusta )',
          no: 'Maksimalt antall valg ( 0 ingen grense )',
          sv: 'Maximalt antal alternativ att välja ( 0 ingen gräns )',
        }),
        [0],
        'number'
      )}
      {renderInput(
        selectedQuestion?.assets?.minOptions || '',
        (id, value) => {
          updateSelectedQuestion({
            ...selectedQuestion,
            assets: { ...selectedQuestion?.assets, minOptions: value },
          } as IntegrationQuestion);
          const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
          if (x) x.assets = { ...x.assets, minOptions: value };
        },
        'assets.minOptions',
        renderLabel({
          en: 'Min options to select ( 0 no limit )',
          da: 'Min. valgmuligheder ( 0 ingen begrænsning )',
          es: 'Mínimo de opciones a seleccionar ( 0 sin límite )',
          fi: 'Valittavien minimimäärä ( 0 ei rajoitusta )',
          no: 'Minimum antall valg ( 0 ingen begrensning )',
          sv: 'Minsta antal alternativ att välja ( 0 ingen begränsning )',
        }),
        [0],
        'number'
      )}
    </Stack>
  );

  // value  (IMAGE) MULTIPLECHOISE_IMAGE
  const renderImageOptions = (
    <MultipleChoseImageList
      values={selectedQuestion?.value || ["{position:1,value:'',url:''}"]}
      max={15}
      onChange={(value: any[]) => {
        updateSelectedQuestion({
          ...selectedQuestion,
          value: value.map((v) => JSON.stringify(v)),
        } as IntegrationQuestion);
        const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
        if (x) x.value = value.map((v) => JSON.stringify(v));
      }}
    />
  );

  // value
  const renderValues = selectedQuestion ? (
    <ListInput
      key="value"
      label={RenderLabel({
        name: {
          en: 'Responses',
          es: 'Respuestas',
          da: 'Svar',
          fi: 'Vastaukset',
          no: 'Svar',
          sv: 'Svar',
        },
      })}
      values={selectedQuestion.value || []}
      callback={(newValues) => {
        updateSelectedQuestion({
          ...selectedQuestion,
          value: newValues,
        } as IntegrationQuestion);
        const x = integrationQuestions.find((qu) => qu.id === selectedQuestion.id);
        if (x) x.value = newValues;
      }}
    />
  ) : (
    <> </>
  );
  // assets.exclusiveAnswers
  const renderExclusiveAnswers = selectedQuestion ? (
    <ListInput
      key="exclusiveAnswers"
      label={RenderLabel({
        name: {
          en: 'Exclusive answers',
          es: 'Respuestas exclusivas',
          da: 'Eksklusive svar',
          fi: 'Yksinoikeudella vastaukset',
          no: 'Eksklusive svar',
          sv: 'Exklusiva svar',
        },
      })}
      values={
        // eslint-disable-next-line no-nested-ternary
        selectedQuestion?.assets?.exclusiveAnswers
          ? typeof selectedQuestion?.assets?.exclusiveAnswers === 'string'
            ? [selectedQuestion?.assets?.exclusiveAnswers]
            : selectedQuestion?.assets?.exclusiveAnswers
          : []
      }
      callback={(newValues) => {
        updateSelectedQuestion({
          ...selectedQuestion,
          assets: { ...selectedQuestion.assets, exclusiveAnswers: newValues },
        } as IntegrationQuestion);
        const x = integrationQuestions.find((qu) => qu.id === selectedQuestion.id);
        if (x) x.assets = { ...x.assets, exclusiveAnswers: newValues };
      }}
      color="warning"
    />
  ) : (
    <></>
  );

  // assets.options
  const renderOptions = selectedQuestion ? (
    <ListInput
      key="options"
      label={renderLabel({
        en: 'Options',
        es: 'Opciones',
        da: 'Muligheder',
        fi: 'Vaihtoehdot',
        no: 'Alternativer',
        sv: 'Alternativ',
      })}
      values={selectedQuestion.assets?.options || []}
      callback={(newValues) => {
        updateSelectedQuestion({
          ...selectedQuestion,
          assets: { ...selectedQuestion.assets, options: newValues },
        } as IntegrationQuestion);
        const x = integrationQuestions.find((qu) => qu.id === selectedQuestion.id);
        if (x) x.assets = { ...x.assets, options: newValues };
      }}
      color="secondary"
    />
  ) : (
    <></>
  );

  // assets.direction (horizontal, vertical)
  const renderDirection = selectedQuestion && (
    <InputSelectList
      id="assets.direction"
      label={renderLabel({
        en: 'Direction',
        es: 'Dirección',
        da: 'Retning',
        fi: 'Suunta',
        no: 'Retning',
        sv: 'Riktning',
      })}
      value={selectedQuestion.assets?.direction || 'row'}
      values={[
        {
          value: 'row',
          label: {
            en: 'Horizontal',
            es: 'Horizontal',
            da: 'Vandret',
            fi: 'Vaaka',
            no: 'Horisontal',
            sv: 'Horisontell',
          },
        },
        {
          value: 'column',
          label: {
            en: 'Vertical',
            es: 'Vertical',
            da: 'Lodret',
            fi: 'Pysty',
            no: 'Vertikal',
            sv: 'Vertikal',
          },
        },
      ]}
      update={(id, value) => {
        updateSelectedQuestion({
          ...selectedQuestion,
          assets: { ...selectedQuestion.assets, direction: value },
        } as IntegrationQuestion);
        const x = integrationQuestions.find((qu) => qu.id === selectedQuestion.id);
        if (x) x.assets = { ...x.assets, direction: value };
      }}
      defaultValue="horizontal"
    />
  );

  // assets.order (ltr, rtl)
  const renderOrder = selectedQuestion && (
    <InputSelectList
      id="assets.order"
      label={renderLabel({
        en: 'Order',
        es: 'Orden',
        da: 'Rækkefølge',
        fi: 'Järjestys',
        no: 'Rekkefølge',
        sv: 'Ordning',
      })}
      value={selectedQuestion.assets?.order || 'ltr'}
      values={[
        {
          value: 'ltr',
          label: {
            en: 'Left to right',
            es: 'De izquierda a derecha',
            da: 'Venstre til højre',
            fi: 'Vasemmalta oikealle',
            no: 'Venstre til høyre',
            sv: 'Vänster till höger',
          },
        },
        {
          value: 'rtl',
          label: {
            en: 'Right to left',
            es: 'De derecha a izquierda',
            da: 'Højre til venstre',
            fi: 'Oikealta vasemmalle',
            no: 'Høyre til venstre',
            sv: 'Höger till vänster',
          },
        },
      ]}
      update={(id, value) => {
        updateSelectedQuestion({
          ...selectedQuestion,
          assets: { ...selectedQuestion.assets, order: value },
        } as IntegrationQuestion);
        const x = integrationQuestions.find((qu) => qu.id === selectedQuestion.id);
        if (x) x.assets = { ...x.assets, order: value };
      }}
      defaultValue="ltr"
    />
  );

  const renderTypeFields = () => {
    if (!selectedQuestion) return <></>;

    const fields: ReactElement[] = [];
    switch (questionType) {
      case 'TEXT':
        fields.push(<div key="subtitle">{renderSubtitle}</div>);
        fields.push(<div key="generalImage">{renderGeneralImage}</div>);
        fields.push(<div key="placeholder">{renderPlaceholder()}</div>);
        fields.push(<div key="defaultValue">{renderDefaultValue('text')}</div>);
        fields.push(<div key="maxCharacters">{renderMaxCharacters}</div>);
        break;
      case 'LONGTEXT':
        fields.push(<div key="subtitle">{renderSubtitle}</div>);
        fields.push(<div key="generalImage">{renderGeneralImage}</div>);
        fields.push(<div key="placeholder">{renderPlaceholder()}</div>);
        fields.push(<div key="defaultValue">{renderDefaultValue('text')}</div>);
        fields.push(<div key="maxCharacters">{renderMaxCharacters}</div>);
        fields.push(<div key="extraOption">{renderExtraOption}</div>);
        break;
      case 'NUMBER':
        fields.push(<div key="subtitle">{renderSubtitle}</div>);
        fields.push(<div key="generalImage">{renderGeneralImage}</div>);
        fields.push(<div key="placeholder">{renderPlaceholder()}</div>);
        fields.push(<div key="defaultValue">{renderDefaultValue('number')}</div>);
        break;
      case 'RATING_NUMBER':
        fields.push(<div key="subtitle">{renderSubtitle}</div>);
        fields.push(<div key="minMaxValue">{renderMinMaxValue}</div>);
        fields.push(<div key="minMaxPlaceholder">{renderMinMaxPlaceholder}</div>);
        // Order (ltr, rtl)
        fields.push(<div key="order">{renderOrder}</div>);
        // Direction (horizontal, vertical)
        fields.push(<div key="direction">{renderDirection}</div>);
        fields.push(<div key="extraOption">{renderExtraOption}</div>);
        break;
      case 'RADIO':
        fields.push(<div key="subtitle">{renderSubtitle}</div>);
        fields.push(<div key="generalImage">{renderGeneralImage}</div>);
        fields.push(<div key="values">{renderValues}</div>);
        fields.push(<div key="randomize">{renderRandomize}</div>);
        fields.push(<div key="extraOption">{renderExtraOption}</div>);
        break;
      case 'MULTIPLECHOICE':
        fields.push(<div key="subtitle">{renderSubtitle}</div>);
        fields.push(<div key="generalImage">{renderGeneralImage}</div>);
        fields.push(<div key="values">{renderValues}</div>);
        fields.push(<div key="exclusiveAnswers">{renderExclusiveAnswers}</div>);
        fields.push(<div key="extraOption">{renderExtraOption}</div>);
        fields.push(<div key="maxMinOptions">{renderMaxMinOptions}</div>);
        break;
      case 'MULTIPLECHOISE_IMAGE':
        fields.push(<div key="subtitle">{renderSubtitle}</div>);
        fields.push(<div key="randomize">{renderRandomize}</div>);
        fields.push(<div key="multiOption">{renderMultiOption}</div>);
        fields.push(<div key="imageOptions">{renderImageOptions}</div>);
        fields.push(<div key="addTitle">{renderAddTitle}</div>);
        break;
      case 'CONSENT':
        fields.push(<div key="subtitle">{renderSubtitle}</div>);
        break;
      case 'SELECT':
        fields.push(<div key="subtitle">{renderSubtitle}</div>);
        fields.push(<div key="generalImage">{renderGeneralImage}</div>);
        fields.push(<div key="values">{renderValues}</div>);
        fields.push(<div key="placeholder">{renderPlaceholder()}</div>);
        break;
      case 'DATE':
        fields.push(<div key="subtitle">{renderSubtitle}</div>);
        fields.push(<div key="placeholder">{renderPlaceholder()}</div>);
        break;
      case 'BOOLEAN':
        fields.push(<div key="subtitle">{renderSubtitle}</div>);
        fields.push(<div key="generalImage">{renderGeneralImage}</div>);
        fields.push(<div key="addIcon">{renderAddIcon}</div>);
        break;
      case 'PASSWORD':
        fields.push(<div key="subtitle">{renderSubtitle}</div>);
        fields.push(<div key="placeholder">{renderPlaceholder()}</div>);
        break;
      case 'RATING_EMOJI':
        fields.push(<div key="subtitle">{renderSubtitle}</div>);
        fields.push(<div key="minMaxValue">{renderMinMaxValue}</div>);
        fields.push(<div key="minMaxPlaceholder">{renderMinMaxPlaceholder}</div>);
        // Order (ltr, rtl)
        // fields.push(<div key="order">{renderOrder}</div>);
        // Direction (horizontal, vertical)
        // fields.push(<div key="direction">{renderDirection}</div>);

        fields.push(<div key="extraOption">{renderExtraOption}</div>);
        break;
      case 'EMAIL':
        fields.push(<div key="subtitle">{renderSubtitle}</div>);
        fields.push(<div key="placeholder">{renderPlaceholder()}</div>);
        fields.push(<div key="defaultValue">{renderDefaultValue('email')}</div>);
        break;
      case 'CONTACT':
        fields.push(<div key="subtitle">{renderSubtitle}</div>);
        fields.push(<div key="contactName">{renderContactName}</div>);
        fields.push(<div key="contactEmail">{renderContactEmail}</div>);
        break;
      case 'RATING_STAR':
        fields.push(<div key="subtitle">{renderSubtitle}</div>);
        break;
      case 'MULTI_QUESTION_MATRIX':
        fields.push(<div key="subtitle">{renderSubtitle}</div>);
        fields.push(<div key="values">{renderValues}</div>);
        fields.push(<div key="options">{renderOptions}</div>);
        fields.push(<div key="exclusiveAnswers">{renderExclusiveAnswers}</div>);
        fields.push(<div key="randomize">{renderRandomize}</div>);
        break;
      case 'POINT_SYSTEM':
        fields.push(<div key="subtitle">{renderSubtitle}</div>);
        fields.push(<div key="values">{renderValues}</div>);
        fields.push(<div key="randomize">{renderRandomize}</div>);
        break;
      case 'PRIORITY_LIST':
        fields.push(<div key="subtitle">{renderSubtitle}</div>);
        fields.push(<div key="values">{renderValues}</div>);
        fields.push(<div key="randomize">{renderRandomize}</div>);
        break;
      case 'INFO_PAGE':
        fields.push(
          <div key="placeholder">
            {renderPlaceholder(
              {
                en: 'Text screen info',
                es: 'Texto de pantalla',
                da: 'Tekst skærm info',
                fi: 'Tekstiruudun tiedot',
                no: 'Tekst skjerm info',
                sv: 'Textskärmsinfo',
              },
              false
            )}
          </div>
        );
        break;
      default:
        break;
    }

    const renderQuestionTitle = renderInput(
      selectedQuestion?.title || '',
      (id, value) => {
        const newRef = generateRef(value, selectedQuestion?.id);
        // Update the questionRef in the routes
        integrationRoutes.forEach((route) => {
          if (route.questionRef === selectedQuestion?.ref) {
            route.questionRef = newRef;
          }
        });
        updateSelectedQuestion({
          ...selectedQuestion,
          title: value,
          ref: newRef,
        } as IntegrationQuestion);
        const x = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
        if (x) {
          x.title = value;
          x.ref = newRef;
        }
      },
      'title',
      renderLabel({
        en: 'Question title',
        es: 'Título de la pregunta',
        da: 'Spørgsmålstitel',
        fi: 'Kysymyksen otsikko',
        no: 'Spørsmålstittel',
        sv: 'Frågetitel',
      }),
      [],
      'text',
      true,
      renderLabel({
        en: 'Question title',
        es: 'Título de la pregunta',
        da: 'Spørgsmålstitel',
        fi: 'Kysymyksen otsikko',
        no: 'Spørsmålstittel',
        sv: 'Frågetitel',
      }),
      {},
      false,
      () => {},
      2
    );

    const renderQuestionType = (
      <Stack direction="column" spacing={1} alignItems="center" justifyContent="flex-start">
        <InputLabel
          shrink
          sx={{
            fontSize: '1.1rem !important',
            color: (theme) => `${theme.palette.text.primary} !important`,
            justifyContent: 'flex-start',
            width: 1,
            pl: 1,
          }}
        >
          {t('text.inputs.questionType')}
        </InputLabel>
        <Stack
          direction="row"
          spacing={1}
          width={1}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            cursor: 'pointer',
            borderRadius: 1,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            padding: 1,
            height: 50,
            '&:hover': { backgroundColor: (theme) => theme.palette.action.hover },
          }}
          onClick={popover.onOpen}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            {renderTypeOrIcon(selectedQuestion?.type, { height: '22px', mr: '8px' })}
            <Typography variant="body2" fontSize={14} sx={{ cursor: 'pointer' }}>
              {t(`text.questionType.${selectedQuestion?.type}`)}
            </Typography>
          </Stack>
          <ExpandMoreIcon
            sx={{
              color: (theme) => `${theme.palette.text.disabled} !important`,
              fontSize: '1.1rem',
            }}
          />
        </Stack>
      </Stack>
    );

    return (
      <Stack direction="column" spacing={2} px={1.5} pb={2}>
        {renderQuestionType}
        <FormGroup sx={{ px: 1, py: 2 }}>
          <FormControlLabel
            control={
              <Switch
                checked={selectedQuestion?.followup || false}
                color="primary"
                className={selectedQuestion?.followup || false ? 'glitter-effect' : ''}
                onChange={() => {
                  updateSelectedQuestion({
                    ...selectedQuestion,
                    followup: !selectedQuestion?.followup,
                  });
                  const i = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
                  if (i) {
                    i.followup = !selectedQuestion?.followup;
                  }
                }}
              />
            }
            label={
              <Stack direction="row" alignItems="center" spacing={1}>
                {selectedQuestion?.followup ? (
                  <SvgPath />
                ) : (
                  <AutoAwesome color="primary" fontSize="small" />
                )}
                <Typography variant="body2" fontSize={14}>
                  {t('text.inputs.followup')}
                </Typography>
              </Stack>
            }
            sx={{ mb: 2 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={selectedQuestion?.require || false}
                color="warning"
                onChange={() => {
                  updateSelectedQuestion({
                    ...selectedQuestion,
                    require: !selectedQuestion?.require,
                  });
                  const i = integrationQuestions.find((qu) => qu.id === selectedQuestion?.id);
                  if (i) {
                    i.require = !selectedQuestion?.require;
                  }
                }}
              />
            }
            label={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Lock color="warning" fontSize="small" />
                <Typography variant="body2" fontSize={14}>
                  {t('text.inputs.required')}
                </Typography>
              </Stack>
            }
          />
        </FormGroup>

        {fields}

        <QuestionTypePopover
          questionsTypePopover={popover}
          onSelect={(ty) => {
            // Update the question type
            updateSelectedQuestion({ ...selectedQuestion, type: ty.id });
            const i = integrationQuestions.find((qu) => qu.id === selectedQuestion.id);
            if (i) {
              i.type = ty.id;
              i.value = getValues(ty.id);
              i.assets = getAssets(ty.id);
            }
            popover.onClose();
          }}
          selected={selectedQuestion?.type}
        />
      </Stack>
    );
  };

  return renderTypeFields();
}

export function ListInput({
  label,
  values,
  callback,
  color = 'primary',
  disabled = false,
  helperText = '',
  sx = {},
}: {
  label: string;
  values: string[];
  callback: (value: string[]) => void;
  color?: string;
  disabled?: boolean;
  helperText?: string;
  sx?: any;
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  return (
    <FormControl fullWidth>
      <Stack direction="column" spacing={2}>
        <InputLabel
          shrink
          sx={{
            fontSize: '1.1rem !important',
            color: (theme) =>
              `${!disabled ? theme.palette.text.primary : theme.palette.text.disabled} !important`,
            ml: -1,
            ...(helperText && { mt: -1, display: 'flex', alignItems: 'center' }),
          }}
        >
          {label}
          {helperText && helperText !== '' && (
            <InfoButton text={helperText} size="medium" buttonSize={45} iconSize={25} />
          )}
        </InputLabel>
        <TextField
          error={error}
          variant="outlined"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (value === '') {
                setError(true);
                setErrorText('required');
                return;
              }
              if (values.includes(value)) {
                setError(true);
                setErrorText('alreadyExists');
                return;
              }
              // Send new value
              setError(false);
              callback([...values, value]);
              setValue('');
            }
          }}
          disabled={disabled}
          helperText={`${t('text.labels.pressEnterToAdd')} ${
            error ? `(${t(`error.${errorText}`)})` : ''
          }`}
          sx={{
            py: 2,
            ...sx,
            '& .MuiInputBase-input.Mui-disabled': {
              color: (theme) => theme.palette.text.disabled,
              borderColor: (theme) => theme.palette.text.disabled,
              cursor: 'not-allowed',
            },
          }}
        />

        <Stack direction="row" spacing={1} flexWrap="wrap" mx={1}>
          {values?.map((v, index) => (
            <Tooltip title={v}>
              <Chip
                key={index}
                label={v}
                color={color as any}
                onDelete={() => {
                  callback(values?.filter((_, i) => i !== index));
                }}
                disabled={disabled}
                sx={{
                  '& .MuiChip-label': {
                    // cut text if is so long
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 300,
                  },
                }}
              />
            </Tooltip>
          ))}
        </Stack>
      </Stack>
    </FormControl>
  );
}
