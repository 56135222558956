// ----------------------------------------------------------------------

import Company from '../../models/company';
import User from '../../models/user';
import { Product } from '../../models/product';
import IntegrationOption from '../../models/integrationOption';
import Integration from '../../models/integration';
import { Metric } from '../../models/metric';
import { IntegrationQuestionType } from '../../models/integration-question-type';
import { IntegrationPage } from '../../models/integration-page';
import { IntegrationQuestion } from '../../models/integration-question';
import IntegrationRoute from '../../models/integrationRoute';

export type ActionMapType<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum CreateDataType {
  USER = 'USER',
  COMPANY = 'COMPANY',
  PRODUCT = 'PRODUCT',
  METRIC = 'METRIC',
}

export type DataStateType = {
  companies: Company[];
  users: User[];
  products: Product[];
  metrics: Metric[];

  categories: string[];
  integrationsFilter: Integration[];
  metricFilter: { group: string; value: string }[];
  productsFilter: Product[];
  feedbackFilter: string[];

  selectedCompany: string;
  integrationTypes: IntegrationQuestionType[];
  integrationOptions: IntegrationOption[];
  integration: Integration | null;
  integrationPages: IntegrationPage[];
  selectedPage: IntegrationPage | null;
  integrationQuestions: IntegrationQuestion[];
  selectedQuestion: IntegrationQuestion | null;
  integrationRoutes: IntegrationRoute[];
  language: string;
};

export type DataContextProps = DataStateType & {
  // Add
  addIntegrationOptions: (option: IntegrationOption[]) => void;
  addIntegrationTypes: (types: IntegrationQuestionType[]) => void;
  // Update
  updateCompaniesState: (companies: Company[], init?: boolean) => void;
  updateUsersState: (users: User[], init?: boolean) => void;
  updateProductsState: (products: Product[], init?: boolean) => void;
  updateMetricsState: (metrics: Metric[], init?: boolean) => void;
  // Filter data
  updateCategories: (categories: string[]) => void;
  updateIntegrationsFilter: (integrations: Integration[]) => void;
  updateMetricFilter: (metrics: { group: string; value: string }[]) => void;
  updateProductsFilter: (products: Product[]) => void;
  updateFeedbackFilter: (feedback: string[]) => void;

  updateSelectedCompanyState: (companyId: string) => void;
  updateIntegrationOptionState: (options: IntegrationOption) => void;
  updateIntegrationState: (integration: Integration | null) => void;
  updateSelectedPage: (page: IntegrationPage | null) => void;
  updateIntegrationPages: (pages: IntegrationPage[]) => void;
  updateSelectedQuestion: (question: IntegrationQuestion | null) => void;
  updateIntegrationQuestions: (questions: IntegrationQuestion[]) => void;
  updateIntegrationRoutes: (routes: IntegrationRoute[]) => void;
  updateLanguage: (language: string) => void;
  // Ref
  generateRef(title: string, id: string): string;
  // Drawer
  open: boolean;
  onToggle: (type: CreateDataType, editableObj?: any) => void;
  onClose: VoidFunction;
  type: CreateDataType | null;
  company: Company | null;
  user: User | null;
  product: Product | null;
  metric: Metric | null;
};
