import axios, { endpoints } from '../utils/axios';
import getHeader from './header';
import IntegrationOption from '../models/integrationOption';
import Integration from '../models/integration';
import IntegrationConnection from '../models/integrationConnection';
import { getFileFormatById, getIntegrationTypeFormat } from '../components/file-thumbnail';
import { clearFilterToSend } from '../components/dataTable/utils';
import { IntegrationStyle } from '../models/integration-style';
import { IntegrationQuestionType } from '../models/integration-question-type';
import { IntegrationTheme } from '../models/integration-theme';
import { Metric } from '../models/metric';
import { IntegrationPage } from '../models/integration-page';
import { IntegrationQuestion } from '../models/integration-question';
import IntegrationRoute from '../models/integrationRoute';

export async function getIntegrations(
  companyId: string,
  skip: number = 0,
  limit: number = 50,
  order: string = 'name DESC',
  filters?: any,
  include?: any[]
): Promise<Integration[]> {
  try {
    const header = await getHeader();
    const filter = {
      skip,
      limit,
      where: { companyId, ...clearFilterToSend(filters, 'integration'), status: 'ACTIVE' },
      order: order || 'createdAt DESC',
      include,
    };

    const response = await axios.get(
      `${endpoints.integrations.list}?filter=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getIntegrationCount(companyId: string, filters?: any): Promise<number> {
  try {
    const header = await getHeader();
    const filter = { companyId, ...clearFilterToSend(filters, 'integration'), status: 'ACTIVE' };

    const response = await axios.get(
      `${endpoints.integrations.count}?where=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data.count;
    }
    return 0;
  } catch (e) {
    console.error(e);
    return 0;
  }
}

export async function getIntegration(id: string, include?: any[]): Promise<Integration | null> {
  try {
    const header = await getHeader();

    const filter = {
      include,
    };

    const response = await axios.get(
      `${endpoints.integrations.id(id)}?filter=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function getIntegrationNameList(
  companyId: string,
  order: string = 'name ASC',
  excludeId?: string,
  filters?: any
): Promise<Integration[]> {
  try {
    const header = await getHeader();
    const filter = {
      where: {
        companyId,
        id: { neq: excludeId },
        ...clearFilterToSend(filters, 'integration'),
        status: 'ACTIVE',
      },
      order: order || 'createdAt DESC',
      fields: { id: true, name: true },
    };

    const response = await axios.get(
      `${endpoints.integrations.list}?filter=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getIntegrationOptions(identity?: string): Promise<IntegrationOption[]> {
  try {
    const header = await getHeader();
    // where: {status: 'ACTIVE'}
    const filter: any = { order: 'name ASC' };

    if (identity) filter.where = { identity };

    const response = await axios.get(
      `${endpoints.integrations.options}?filter=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getIntegrationOptionsList(
  company: string,
  identity: string[] = []
): Promise<Integration[]> {
  try {
    const header = await getHeader();
    const filter: any = {
      fields: { id: true, name: true, identity: true },
      order: 'name ASC',
      where: {
        companyId: company,
        status: 'ACTIVE',
      },
    };

    if (identity?.length > 0) filter.where = { ...filter.where, identity: { inq: identity } };

    const response = await axios.get(
      `${endpoints.integrations.list}?filter=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getIntegrationStatus(
  identity: string
): Promise<IntegrationConnection | null> {
  try {
    const header = await getHeader();

    const response = await axios.get(
      `${endpoints.integrations.connection.status}${identity}`,
      header
    );

    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function getIntegrationDriveFolderContent(
  identity: string,
  folderId?: string
): Promise<any> {
  try {
    const header = await getHeader();
    const body = {
      url: 'https://www.googleapis.com/drive/v3/files',
      method: 'GET',
      queryString: {
        q: `'${
          folderId || 'root'
        }' in parents and (mimeType = 'application/vnd.google-apps.document' or mimeType = 'application/vnd.google-apps.folder')`,
        fields: 'nextPageToken, files(id, name, createdTime, modifiedTime, mimeType)',
        orderBy: 'folder,name asc',
        includeItemsFromAllDrives: 'True',
        supportsAllDrives: 'True',
      },
    };

    const response = await axios.post(
      `${endpoints.integrations.connection.call}${identity}`,
      body,
      { ...header }
    );

    if (response.status === 200) {
      return response.data.data;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function postIntegrationFile(
  file: any,
  userId: string,
  companyId: string
): Promise<Integration | null> {
  try {
    const header = await getHeader();
    const body = {
      name: file.name,
      description: file.description || '',
      type: file.type || getIntegrationTypeFormat(file.mimeType),
      identity: file.identity || getFileFormatById(file.mimeType),
      external_id: file.id || '',
      companyId: file.companyId || companyId,
      productId: file.productId || `${companyId}_GENERAL`,
      userId,
      lang: file.lang || ['en'],
      wizardId: file.wizardId || '',
    };

    const response = await axios.post(`${endpoints.integrations.list}`, body, { ...header });

    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function patchIntegrationForm(form: Integration): Promise<Integration | null> {
  try {
    const header = await getHeader();
    const body = {
      name: form.name,
      description: form.description,
      status: form.status,
      lang: form.lang,
      wizardId: form.wizardId || '',
      // productId: form.productId,
    };

    const response = await axios.patch(`${endpoints.integrations.list}/${form.id}`, body, {
      ...header,
    });

    if (response.status === 204) {
      return form;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function postIntegrationForm(
  form: Integration,
  conf: Record<string, string | boolean | number>,
  userId: string,
  companyId: string
) {
  try {
    const header = await getHeader();
    const body = {
      name: form.name,
      description: form.description || '',
      type: form.type || 'DOCUMENT',
      identity: form.identity,
      companyId,
      productId: form.productId || `${companyId}_GENERAL`,
      userId,
      conf,
      lang: form.lang || ['en'],
    };

    const response = await axios.post(`${endpoints.integrations.list}`, body, { ...header });

    if (response.status === 200) {
      return response.data.data;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function patchIntegration(
  form: Integration,
  conf: Record<string, string | boolean | number>,
  companyId: string
) {
  try {
    const header = await getHeader();
    const body = {
      name: form.name,
      description: form.description || '',
      productId: form.productId || `${companyId}_GENERAL`,
      conf,
      lang: form.lang || ['en'],
    };

    const response = await axios.patch(`${endpoints.integrations.list}/${form.id}`, body, {
      ...header,
    });

    if (response.status === 204) {
      return form;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function getIntegrationsQuestions(id: string): Promise<IntegrationQuestion[]> {
  try {
    const header = await getHeader();
    const filter = { order: 'position ASC', where: { status: 'ACTIVE' } };

    const response = await axios.get(
      `${endpoints.integrations.questions.id(id)}?filter=${JSON.stringify(filter)}`,
      { ...header }
    );

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function postIntegrationQuestion(
  id: string,
  conf: Record<string, any>
): Promise<IntegrationQuestion | null> {
  try {
    const header = await getHeader();
    const body = {
      title: conf.title,
      type: conf.type,
      typeId: conf.typeId,
      require: conf.require,
      followup: conf.followup !== null ? conf.followup : false,
      value: conf.value,
      defaultValue: conf.defaultValue,
      position: conf.position,
      external_id: '',
      assets: conf.assets || {},
      status: conf.status || 'DRAFT',
      integrationPageId: conf.integrationPageId,
      ref: conf.ref || '',
    };

    const response = await axios.post(`${endpoints.integrations.questions.id(id)}`, body, {
      ...header,
    });

    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function patchIntegrationQuestion(id: string, conf: Record<string, any>) {
  try {
    const header = await getHeader();
    const generateRef = (title: string) =>
      title && title !== ''
        ? title.toLowerCase().replace(/[^a-z0-9]/g, '')
        : Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

    const body = {
      id: conf.id,
      title: conf.title,
      type: conf.type,
      typeId: conf.typeId,
      ref: conf.ref || generateRef(conf.title),
      require: conf.require,
      followup: conf.followup !== null ? conf.followup : false,
      value: conf.value,
      defaultValue: conf.defaultValue,
      position: conf.position,
      external_id: '',
      assets: conf.assets || {},
      status: conf.status === 'DRAFT' ? 'ACTIVE' : conf.status || 'ACTIVE',
      integrationPageId: conf.integrationPageId,
    };

    const response = await axios.patch(`${endpoints.integrations.questions.id(id)}`, body, {
      ...header,
    });

    if (response.status === 204) {
      return true;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function deleteIntegrationQuestion(formId: string, id: string) {
  try {
    const header = await getHeader();
    const where = JSON.stringify({ id });

    const response = await axios.delete(
      `${endpoints.integrations.questions.id(formId)}?where=${where}`,
      { ...header }
    );

    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

// get integration page
export async function getIntegrationPage(id: string): Promise<IntegrationPage[]> {
  try {
    const header = await getHeader();

    const filter = {
      where: { status: 'ACTIVE' },
      include: [{ relation: 'integrationPageRoutes' }],
      order: 'position ASC',
    };

    const response = await axios.get(
      `${endpoints.integrations.page.id(id)}?filter=${JSON.stringify(filter)}`,
      { ...header }
    );

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

// post integration page
export async function postIntegrationPage(
  id: string,
  position: number,
  status: string = 'DRAFT'
): Promise<IntegrationPage | null> {
  try {
    const header = await getHeader();
    const body = {
      position,
      status,
    };

    const response = await axios.post(`${endpoints.integrations.page.id(id)}`, body, { ...header });

    if (response.status === 200) {
      return response.data;
    }

    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

// patch integration page
export async function patchIntegrationPage(id: string, page: IntegrationPage): Promise<boolean> {
  try {
    const header = await getHeader();
    const body = {
      id: page.id,
      position: page.position,
      status: page.status,
    };

    const response = await axios.patch(`${endpoints.integrations.page.id(id)}`, body, {
      ...header,
    });

    return response.status >= 200 && response.status < 300;
  } catch (e) {
    console.error(e);
    return false;
  }
}

// delete page
export async function deleteIntegrationPage(id: string, pageId: string): Promise<boolean> {
  try {
    const header = await getHeader();

    const response = await axios.delete(`${endpoints.integrations.page.id(id)}/${pageId}`, {
      ...header,
    });

    return response.status >= 200 && response.status < 300;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function getIntegrationQuestionsTypes(): Promise<IntegrationQuestionType[]> {
  try {
    const header = await getHeader();

    const filter = {
      order: 'id ASC',
    };

    const response = await axios.get(
      `${endpoints.integrations.questions.types}?filter=${JSON.stringify(filter)}`,
      { ...header }
    );

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function postIntegrationStyling(
  id: string,
  style: IntegrationStyle
): Promise<IntegrationStyle | null> {
  try {
    const header = await getHeader();
    const body = {
      logo: style.logo || '',
      title: style.title || '',
      favIcon: style.favIcon || '',
      backgroundColor: style.backgroundColor || '',
      companyName: style.companyName || '',
      helpUrl: style.helpUrl || '',
      privacyPolicyUrl: style.privacyPolicyUrl || '',
      successMessage: style.successMessage || '',
      redirectLink: style.redirectLink || '',
      redirectTime: style.redirectTime || 0,
      startMessage: style.startMessage || '',
      addLogoInMsg: style.addLogoInMsg || false,
    };

    const response = await axios.post(`${endpoints.integrations.questions.style(id)}`, body, {
      ...header,
    });

    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function patchIntegrationStyling(id: string, style: IntegrationStyle) {
  try {
    const header = await getHeader();
    const body = {
      logo: style.logo || '',
      title: style.title || '',
      favIcon: style.favIcon || '',
      backgroundColor: style.backgroundColor || '',
      companyName: style.companyName || '',
      helpUrl: style.helpUrl || '',
      privacyPolicyUrl: style.privacyPolicyUrl || '',
      successMessage: style.successMessage || '',
      redirectLink: style.redirectLink || '',
      redirectTime: style.redirectTime || 0,
      startMessage: style.startMessage || '',
      addLogoInMsg: style.addLogoInMsg || false,
      integrationThemeId: id,
    };

    const response = await axios.patch(`${endpoints.integrations.questions.style(id)}`, body, {
      ...header,
    });

    if (response.status === 204) {
      return true;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

// get integration route by page Id
export async function getIntegrationRoute(id: string): Promise<IntegrationRoute[]> {
  try {
    const header = await getHeader();

    const filter = {
      where: { status: 'ACTIVE' },
    };

    const response = await axios.get(
      `${endpoints.integrations.page.route(id)}?filter=${JSON.stringify(filter)}`,
      { ...header }
    );

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

// post integration route
export async function postIntegrationRoute(
  id: string,
  route: Record<string, any>
): Promise<IntegrationRoute | null> {
  try {
    const header = await getHeader();
    const body = {
      questionRef: route.questionRef,
      typeCondition: route.typeCondition,
      typeOperator: route.typeOperator,
      value: route.value,
      transition: route.transition,
      transitionDestiny: route.transitionDestiny,
      status: route.status,
      integrationPageId: route.integrationPageId,
    };

    const response = await axios.post(`${endpoints.integrations.page.route(id)}`, body, {
      ...header,
    });

    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

// patch integration route
export async function patchIntegrationRoute(
  id: string,
  route: Record<string, any>
): Promise<IntegrationRoute | null> {
  try {
    const header = await getHeader();
    const body = {
      ...route,
    };

    const response = await axios.patch(`${endpoints.integrations.page.route(id)}`, body, {
      ...header,
    });

    return response.status >= 200 && response.status < 300 ? response.data : null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function getCsvFileUrl(id: string): Promise<any> {
  try {
    const header = await getHeader();

    const response = await axios.get(`${endpoints.integrations.exports(id)}`, { ...header });

    if (response.status === 200) {
      return {
        data: response.data,
        name: response.headers['content-disposition']?.split('filename=')[1] || '',
      };
    }
    return '';
  } catch (e) {
    console.error(e);
    return '';
  }
}

export async function getIntegrationLang(): Promise<string[]> {
  const defaultLang = ['en', 'da', 'fi', 'no', 'es', 'sv'];
  try {
    const header = await getHeader();

    const response = await axios.get(`${endpoints.integrations.lang}`, { ...header });

    if (response.status === 200) {
      return response.data;
    }
    return defaultLang;
  } catch (e) {
    console.error(e);
    return defaultLang;
  }
}

export async function postWizardQuestions(
  query: string,
  productId: string
): Promise<{ id: string; questions: Record<string, any>[] } | null> {
  try {
    const header = await getHeader();
    const body = {
      query,
      productId,
    };

    const response = await axios.post(`${endpoints.integrations.questions.wizard}`, body, {
      ...header,
    });

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    throw new Error('Error getting wizard questions');
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function postIntegrationImageUrl(newFile: File): Promise<string> {
  try {
    const header = await getHeader();
    header.headers['Content-Type'] = 'multipart/form-data';

    const formData = new FormData();
    formData.append('file', newFile);

    const response = await axios.post(`${endpoints.integrations.image}`, formData, header);

    if (response.status === 200) {
      return response.data.url;
    }
    return '';
  } catch (e) {
    console.error(e);
    return '';
  }
}

// get integration metrics list
export async function getIntegrationMetrics(
  integrationId: string,
  filters?: any
): Promise<Metric[]> {
  try {
    const header = await getHeader();

    const filter = {};

    const response = await axios.get(
      `${endpoints.integrations.metrics.integration(integrationId)}?filter=${JSON.stringify(
        filter
      )}`,
      header
    );

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

// post integration metric
export async function postIntegrationMetric(
  integrationId: string,
  metricId: string
): Promise<boolean> {
  try {
    const header = await getHeader();

    const response = await axios.post(
      `${endpoints.integrations.metrics.action(integrationId, metricId)}`,
      {},
      { ...header }
    );

    return response.status >= 200 && response.status < 300;
  } catch (e) {
    console.error(e);
    return false;
  }
}

// delete integration metric
export async function deleteIntegrationMetric(
  integrationId: string,
  metricId: string
): Promise<boolean> {
  try {
    const header = await getHeader();

    const response = await axios.delete(
      `${endpoints.integrations.metrics.action(integrationId, metricId)}`,
      { ...header }
    );

    return response.status >= 200 && response.status < 300;
  } catch (e) {
    console.error(e);
    return false;
  }
}

// get theme list
export async function getTheme(
  companyId: string,
  skip: number = 0,
  limit: number = 50,
  order: string = 'updatedAt DESC',
  filters?: any,
  include?: any[]
): Promise<IntegrationTheme[]> {
  try {
    const header = await getHeader();

    const filter = {
      where: {
        companyName: companyId,
        ...clearFilterToSend(filters, 'integration'),
        status: 'ACTIVE',
      },
      order,
      include,
      skip,
      limit,
    };

    const response = await axios.get(
      `${endpoints.integrations.theme.list}?filter=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

// get theme count
export async function getThemeCount(companyId: string, filters?: any): Promise<number> {
  try {
    const header = await getHeader();
    const filter = {
      companyName: companyId,
      ...clearFilterToSend(filters, 'integration'),
      status: 'ACTIVE',
    };

    const response = await axios.get(
      `${endpoints.integrations.theme.count}?where=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data.count;
    }

    return 0;
  } catch (e) {
    console.error(e);
    return 0;
  }
}

// post theme
export async function postTheme(theme: Record<string, string>): Promise<boolean> {
  try {
    const header = await getHeader();
    const body = {
      ...theme,
    };

    const response = await axios.post(`${endpoints.integrations.theme.list}`, body, { ...header });

    return response.status === 200;
  } catch (e) {
    console.error(e);
    return false;
  }
}

// patch theme
export async function patchTheme(id: string, theme: Record<string, string>): Promise<boolean> {
  try {
    const header = await getHeader();
    const body = {
      id,
      ...theme,
    };

    const response = await axios.patch(`${endpoints.integrations.theme.list}`, body, { ...header });

    return response.status === 204;
  } catch (e) {
    console.error(e);
    return false;
  }
}

// delete theme
export async function deleteTheme(id: string): Promise<boolean> {
  try {
    const header = await getHeader();

    const response = await axios.delete(`${endpoints.integrations.theme.list}/${id}`, {
      ...header,
    });

    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (e) {
    console.error(e);
    return false;
  }
}

// get answers
export async function getAnswers(id: string, where: any[] = []): Promise<any> {
  try {
    const header = await getHeader();

    const filter = {
      where: {
        and: where
          ?.filter((m) => m.values?.length > 0)
          .map((m) => ({
            name: m.name,
            values: {
              inq: m.values,
            },
          })),
      },
    };

    const response = await axios.get(
      `${endpoints.integrations.answers(id)}?filter=${JSON.stringify(
        filter.where.and.length > 0 ? filter : {}
      )}`,
      header
    );

    return response.status >= 200 && response.status < 300 ? response.data : [];
  } catch (e) {
    console.error(e);
    return [];
  }
}
