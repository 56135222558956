export class IFilters {
  id: string[];

  name: string;

  status: any[];

  identity: any[];

  integration: any[];

  productId: any[];

  category: any[];

  feedback: any[];

  startDate: Date | null;

  endDate: Date | null;

  metrics: any[];

  completed: boolean;

  quality: any[];

  constructor({
    id,
    name,
    status,
    identity,
    integration,
    productId,
    category,
    feedback,
    startDate,
    endDate,
    metrics,
    completed,
    quality,
  }: {
    id?: string[];
    name?: string;
    status?: any[];
    identity?: any[];
    integration?: any[];
    productId?: any[];
    category?: any[];
    feedback?: any[];
    startDate?: Date | null;
    endDate?: Date | null;
    metrics?: any[];
    completed?: boolean;
    quality?: any[];
  }) {
    this.id = id || [];
    this.name = name || '';
    this.status = status || [];
    this.identity = identity || [];
    this.integration = integration || [];
    this.productId = productId || [];
    this.category = category || [];
    this.feedback = feedback || [];
    this.startDate = startDate || null;
    this.endDate = endDate || null;
    this.metrics = metrics || [];
    this.completed = completed || false;
    this.quality = quality || [];
  }
}
