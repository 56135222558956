/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect} from 'react';
import {useDataContext} from '../../components/data';
import {getIntegrationOptions, getIntegrationOptionsList} from '../../services/integrations';
import {getCompanyProducts} from '../../services/companies';
import {IMetrics} from '../../models/feedback';
import {getMetricsList} from '../../services/metrics';

const callMetrics = (selectedCompany: string, skip: number, limit: number) =>
  getMetricsList(selectedCompany, {}, skip, limit);

export default function IntegrationPopover() {
  const {
    selectedCompany,
    addIntegrationOptions,
    updateCategories,
    updateIntegrationsFilter,
    updateMetricFilter,
    updateProductsFilter,
    updateFeedbackFilter,
  } = useDataContext();

  const groupMetrics = (res: IMetrics[]) => {
    const options: { group: string; value: string }[] = [];

    res.forEach((m) => {
      m.metrics_values.forEach((v) => {
        options.push({
          group: m.metrics_name,
          value: v,
        });
      });
    });

    updateMetricFilter(options);
  };

  const getIntegrationOptionsList1 = useCallback(() => {
    getIntegrationOptions().then((integrations) => {
      addIntegrationOptions(integrations);
    });
  }, []);
  /*
    const getCompanyProductsByParts = useCallback(async () => {
      let offset = 0;
      const limit = 100;
      let products: any[] = [];
      let fetchedProducts;

      do {
        const promises = Array.from({ length: 5 }, (_, i) =>
          getProducts(selectedCompany, offset + i * limit, limit, '', {}, { id: true, name: true })
        );
        const results = await Promise.all(promises);
        fetchedProducts = results.flat();
        if (fetchedProducts.length > 0) {
          products = products.concat(fetchedProducts);
          offset += limit * promises.length;
        }
      } while (fetchedProducts.length === limit * promises.length);

      if (products.length > 0) updateProductsFilter(products);
    }, [selectedCompany]);
*/
  const getMetricsListByParts = useCallback(async () => {
    let offset = 0;
    const limit = 50;
    let metrics: any[] = [];
    let fetchedMetrics;

    do {
      // eslint-disable-next-line no-await-in-loop
      fetchedMetrics = await callMetrics(selectedCompany, offset, limit);
      if (fetchedMetrics.length > 0) {
        metrics = metrics.concat(fetchedMetrics);
        offset += limit;
      }
    } while (fetchedMetrics.length === limit);

    if (metrics.length > 0) groupMetrics(metrics);
  }, [selectedCompany]);

  useEffect(() => {
    if (!selectedCompany) return;

    getCompanyProducts(selectedCompany).then((res) => {
      if (res) updateCategories(res);
    });

    getIntegrationOptionsList(selectedCompany).then((res) => {
      if (res) updateIntegrationsFilter(res);
    });

    getMetricsListByParts();
    // getCompanyProductsByParts();
  }, [selectedCompany]);

  return <></>;
}
